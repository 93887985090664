import moment from 'moment';

export type MEAT_CATEGORY_TYPE =
  | 'beef'
  | 'pork'
  | 'chicken'
  | 'horsemeat'
  | 'lamb'
  | 'game';

export type MeatItemDataType = {
  label?: string;
  // カテゴリーによって名称が違う場合
  labelPattern?: {
    [x: string]: string;
  };
  isSideNav?: boolean;
  // 単位
  unit?: string;
  type?: 'bool' | 'string' | 'int' | 'checkbox' | 'date' | 'time';
  // 年月日の年の範囲（現在を基準）
  dateRange?: number[];
  // チェックボックスでラベルを使用せずに表示する場合
  checkboxLabel?: string;
  show?: MEAT_CATEGORY_TYPE[];
  placeholder?: string;
  helperText?: string;
  required?: boolean;
  // バリデーション
  validate?: {
    // 最大文字数
    max?: number;
  };
  defaultValue?: any;
  // 以下の条件を満たせば必須に変更
  linkRequired?: {
    name: string;
    value: string | true;
  };
  // 以下の条件を満たせば表示
  linkShow?: {
    name: string;
    value: string | string[] | true;
    // 適応するカテゴリー
    category?: MEAT_CATEGORY_TYPE;
    helper?: string;
  };
  // 以下の条件を満たせばdisabledに変更
  linkDisabled?: {
    name: string;
    value: string | true;
    helper?: string;
  };
  // 以下の条件を満たせばtargetの値と同じ値に置き換える
  linkValue?: {
    name: string;
    value: string | true;
    target: string;
  };
  select?: {
    show?: MEAT_CATEGORY_TYPE[];
    values: (
      | {
          label?: string;
          value: string;
        }
      | string
    )[];
  }[];
  errorName?: string;
};

export interface MeatItemType {
  [x: string]: MeatItemDataType;
}

/**
 * MeatSale固有のデータ
 */
export const MEAT_ITEMS: MeatItemType = {
  // 部位
  part: {
    label: '部位',
    required: true,
  },
  // 産地
  origin: {
    label: '産地',
    isSideNav: true,
    required: true,
    select: [
      {
        values: ['国産', '外国産'],
      },
    ],
  },
  // 産地詳細
  origin_detail: {
    linkRequired: {
      name: 'origin',
      value: '外国産',
    },
    placeholder: '都道府県や国名を入力してください',
    helperText:
      '※国産の場合に都道府県名を表示する義務はありません。国産を選択だけでもOK',
    errorName: '国名',
  },
  // 冷凍表示
  frozen_flag: {
    label: '冷凍表示',
    required: true,
    select: [
      {
        values: [
          '解凍品',
          '冷蔵',
          '冷凍',
          'エアーブラスト冷凍',
          'リキッド冷凍',
          'コンタクト冷凍',
          '液化ガス冷凍',
          '特殊方式冷凍',
        ],
      },
    ],
  },
  // 消費期限
  expiration_date: {
    label: '消費期限',
    required: true,
    type: 'date',
    dateRange: [0, 10],
  },
  // 消費者による保存の方法
  storage_method: {
    label: '消費者による保存の方法',
    required: true,
    placeholder: '例）10度以下で保存',
    defaultValue: '10度以下で保存',
  },
  // 内容量
  quantity: {
    label: '内容量',
    type: 'int',
    unit: 'g',
    placeholder: '例）1500',
    required: true,
  },
  // 加工者（氏名・名称）
  processor_name: {
    label: '加工者（氏名・名称）',
    required: true,
  },
  // 加工者（住所）
  processor_address: {
    label: '加工者（住所）',
    required: true,
  },
  // 加工日
  processed_at: {
    label: '加工日',
    type: 'date',
    dateRange: [-2, 1],
  },
  // 用途
  purpose: {
    label: '用途',
    required: true,
    isSideNav: true,
    select: [
      {
        show: ['beef', 'chicken', 'horsemeat', 'lamb', 'game'],
        values: ['生食用', '加熱用'],
      },
      {
        show: ['pork'],
        values: ['加熱用'],
      },
    ],
  },
  // 屠畜場（名称）
  slaughterhouse_name: {
    label: '屠畜場（名称）',
    labelPattern: {
      chicken: '食鳥処理場（名称）',
      game: '食肉処理場',
    },
    linkRequired: {
      name: 'purpose',
      value: '生食用',
    },
  },
  // 屠畜場（都道府県）
  slaughterhouse_prefecture: {
    label: '屠畜場（都道府県）',
    show: ['beef', 'pork', 'chicken', 'horsemeat', 'lamb'],
    labelPattern: {
      chicken: '食鳥処理場（都道府県）',
    },
    linkRequired: {
      name: 'purpose',
      value: '生食用',
    },
  },
  // 屠畜日
  slaughter_date: {
    label: '屠畜日',
    type: 'date',
    dateRange: [-2, 1],
  },
  // 落札後屠畜
  is_slaughter_after_bidding: {
    checkboxLabel: '落札後屠畜',
    type: 'bool',
  },
  // 品種
  breed: {
    label: '品種',
    select: [
      {
        show: ['beef'],
        values: [
          '黒毛和種',
          '褐毛和種',
          '日本短角種',
          '無角和種',
          '交雑種',
          'ホルスタイン種',
          'アンガス種',
          'ヘレフォード種',
          'マリーグレー種',
          'ブラーマン種',
          'その他',
        ],
      },
      {
        show: ['pork'],
        values: [
          'ランドレース(L)',
          '大ヨークシャー(W)',
          '中ヨークシャー(Y)',
          'ハンプシャー(H)',
          'デュロック(D)',
          'バークシャー(B)（黒豚）',
          'トウキョウX(TX)',
          '三元交配(LWD)',
          '三元交配(LDB)',
          '三元交配(LDK)',
          '三元交配(LWB)',
          'その他',
        ],
      },
      {
        show: ['chicken'],
        values: [
          '白色コーニッシュ',
          '白色プリマスロック',
          '横斑プリマスロック',
          'ニューハンプシャー',
          'シャモ',
          'アローカナ',
          '烏骨鶏',
          'ロードアイランドレッド',
          '青森シャモロック',
          '川俣シャモ',
          '上州地鶏',
          '奥美濃古地鶏',
          '長州黒かしわ',
          '土佐はちきん地鶏',
          'はかた地どり',
          'みやざき地頭鶏',
          '比内地鶏',
          'やまがた地鶏',
          '会津地鶏',
          '奥久慈しゃも',
          '名古屋コーチン',
          '大和肉鶏',
          '阿波尾鶏',
          '長崎対馬地どり',
          '天草大王',
          '黒さつま鶏',
          'その他',
        ],
      },
      {
        show: ['horsemeat'],
        values: [
          'ブルトン',
          'ベルジャン',
          'ペルシュロン',
          'ペルブルジャン',
          'サラブレッド',
          'その他',
        ],
      },
      {
        show: ['lamb'],
        values: [
          'サフォーク',
          'ロムニー',
          'テクセル',
          'レスター',
          'サウスダウン',
          'チェビオット',
          'コリデール',
          'ランブイエ・メリノ',
          'オーストラリアン・メリノ',
          'オックスフォード・ダウン',
          'ハンプシャー・ダウン',
          'カンタベリー・ラム',
          'その他',
        ],
      },
      {
        show: ['game'],
        values: [
          '鹿',
          '猪',
          '熊',
          '鴨',
          'ウサギ',
          'キジ',
          'ハト',
          'ウズラ',
          'その他',
        ],
      },
    ],
  },
  // 地鶏
  is_jidori: {
    checkboxLabel: '地鶏',
    show: ['chicken'],
    type: 'bool',
  },
  // 天然・畜養・畜産
  source: {
    label: '天然・畜養・畜産',
    show: ['game'],
    select: [
      {
        values: ['天然（ジビエ）', '畜養', '畜産'],
      },
    ],
  },
  // 捕獲方法
  trapping_method: {
    label: '捕獲方法',
    show: ['game'],
    select: [
      {
        values: [
          '無双網',
          '張り網',
          '突き網',
          '投げ網',
          'くくりわな',
          'はこわな',
          'はこおとし',
          '囲いわな',
          'スラッグ弾',
          '散弾',
          '空気銃',
        ],
      },
    ],
  },
  // 止め刺しの方法（道具）
  stabbing_tool: {
    label: '止め刺しの方法（道具）',
    show: ['game'],
    select: [
      {
        values: [
          'スラッグ弾',
          '散弾',
          '空気銃',
          'ナイフ等',
          '槍',
          '電殺器',
          'その他',
        ],
      },
    ],
  },
  // 止め刺しの方法（部位）
  stabbing_location: {
    label: '止め刺しの方法（部位）',
    show: ['game'],
    select: [
      {
        values: ['頭部', '首', '心臓', '頸動脈', 'その他'],
      },
    ],
  },
  // 止め刺しの方法（回数）
  stabbing_times: {
    label: '止め刺しの方法（回数）',
    show: ['game'],
    checkboxLabel: '一撃',
    type: 'bool',
  },
  // 捕獲から止め刺しまでの時間
  time_from_capture_to_stabbing: {
    label: '捕獲から止め刺しまでの時間',
    show: ['game'],
    select: [
      {
        values: ['直後', '〜3時間', '〜6時間', '〜12時間', '〜24時間'],
      },
    ],
  },
  // 止め刺しから血抜きまでの時間
  time_from_stabbing_to_bleeding: {
    label: '止め刺しから血抜きまでの時間',
    show: ['game'],
    select: [
      {
        values: ['直後', '〜3時間', '〜6時間', '〜12時間', '〜24時間'],
      },
    ],
  },
  // 肥育期間
  fattening_period: {
    label: '肥育期間',
    placeholder: '例）6',
    linkRequired: {
      name: 'is_jidori',
      value: true,
    },
    linkShow: {
      name: 'source',
      value: ['畜養', '畜産'],
      category: 'game',
    },
    unit: 'か月',
  },
  // 肥育方法
  fattening_method: {
    label: '肥育方法',
    type: 'checkbox',
    linkRequired: {
      name: 'is_jidori',
      value: true,
    },
    linkShow: {
      name: 'source',
      value: ['畜養', '畜産'],
      category: 'game',
    },
    select: [
      {
        show: ['beef', 'horsemeat', 'lamb', 'game'],
        values: [
          'グレインフェッド',
          'グラスフェッド',
          '抗生物質不使用',
          '合成抗菌剤不使用',
          '動物性飼料不使用',
          'ホルモン剤不使用（国産使用禁止）',
          '生ワクチン不使用',
          '不活性ワクチン不使用',
          'ｍRNAワクチン不使用',
        ],
      },
      {
        show: ['pork'],
        values: [
          '放牧豚（90日以上放牧）',
          '抗生物質不使用',
          '合成抗菌剤不使用',
          '動物性飼料不使用',
          'ホルモン剤不使用（国産使用禁止）',
          '生ワクチン不使用',
          '不活性ワクチン不使用',
          'ｍRNAワクチン不使用',
        ],
      },
      {
        show: ['chicken'],
        values: [
          '放飼い（3～4週間）',
          '放飼い（～2週間）',
          '抗生物質不使用',
          '合成抗菌剤不使用',
          '動物性飼料不使用',
          'ホルモン剤不使用（国産使用禁止）',
          '生ワクチン不使用',
          '不活性ワクチン不使用',
          'ｍRNAワクチン不使用',
        ],
      },
    ],
  },
  // 性別
  sex: {
    label: '性別',
    isSideNav: true,
    select: [
      {
        show: ['beef', 'pork', 'horsemeat', 'lamb', 'game'],
        values: ['去勢', 'メス', '経産', 'オス'],
      },
      {
        show: ['chicken'],
        values: ['去勢', 'メス', 'オス'],
      },
    ],
  },
  // 最終肥育者（住所）
  final_fattener_address: {
    label: '最終肥育者（住所）',
    show: ['beef', 'chicken', 'horsemeat', 'lamb', 'pork'],
    linkShow: {
      name: 'source',
      value: ['畜養', '畜産'],
      category: 'game',
    },
    linkRequired: {
      name: 'is_jidori',
      value: true,
    },
  },
  // 最終肥育者（氏名・名称）
  final_fattener_name: {
    label: '最終肥育者（氏名・名称）',
    show: ['beef', 'chicken', 'horsemeat', 'lamb', 'pork'],
    linkShow: {
      name: 'source',
      value: ['畜養', '畜産'],
      category: 'game',
    },
    linkRequired: {
      name: 'is_jidori',
      value: true,
    },
  },
  // 出荷者（住所）
  shipper_address: {
    label: '出荷者（住所）',
    helperText: '※出荷者と最終肥育者が異なる場合に記入',
  },
  // 出荷者（氏名・名称）
  shipper_name: {
    label: '出荷者（氏名・名称）',
    helperText: '※出荷者と最終肥育者が異なる場合に記入',
  },
  // 農場HACCP
  farm_HACCP: {
    label: '農場HACCP',
    show: ['beef', 'pork', 'horsemeat', 'lamb', 'chicken'],
    type: 'bool',
    checkboxLabel: '認証済み',
  },
  // 生産情報公表JAS規格
  production_information_JAS_standard: {
    label: '生産情報公表JAS規格',
    show: ['beef', 'pork'],
    type: 'bool',
    checkboxLabel: '認証済み',
  },
  // 国産ジビエ認証
  domestic_game_certification: {
    label: '国産ジビエ認証',
    show: ['game'],
    type: 'bool',
    checkboxLabel: '認証済み',
    linkShow: {
      name: 'breed',
      value: ['鹿', '猪'],
    },
  },
  // 父の名
  father_name: {
    label: '父の名',
    show: ['beef', 'horsemeat'],
  },
  // 母父の名
  grandfather_name: {
    label: '母父の名',
    show: ['beef', 'horsemeat'],
  },
  // 祖母の父の名
  great_grandfather_name: {
    label: '祖母の父の名',
    show: ['beef', 'horsemeat'],
  },
  // 父の品種
  father_breed: {
    label: '父の品種',
    show: ['chicken'],
  },
  // 母の品種
  mother_breed: {
    label: '母の品種',
    show: ['chicken'],
  },
  // 出生地
  birthplace: {
    label: '出生地',
    show: ['beef', 'pork', 'horsemeat', 'lamb', 'chicken'],
    linkDisabled: {
      name: 'introduction_type',
      value: '自家産',
      helper: '産地と同じ値が入ります',
    },
    linkValue: {
      name: 'introduction_type',
      value: '自家産',
      target: 'origin_detail',
    },
  },
  // 生年月日
  birthday: {
    label: '生年月日',
    show: ['beef', 'pork', 'horsemeat', 'lamb', 'chicken'],
    type: 'date',
  },
  // 導入種類
  introduction_type: {
    label: '導入種類',
    show: ['beef', 'pork', 'horsemeat', 'lamb', 'chicken'],
    select: [
      {
        show: ['beef', 'horsemeat', 'lamb', 'game'],
        values: ['育成牛', '子牛（素牛）', '自家産'],
      },
      {
        show: ['pork'],
        values: ['素豚', '自家産'],
      },
      {
        show: ['chicken'],
        values: ['外部導入', '自家産'],
      },
    ],
  },
  // 導入月日
  introduction_date: {
    label: '導入月日',
    show: ['beef', 'pork', 'horsemeat', 'lamb', 'chicken'],
    type: 'date',
    linkDisabled: {
      name: 'introduction_type',
      value: '自家産',
    },
  },
  // 月齢
  age: {
    label: '月齢',
    unit: 'か月齢',
    show: ['beef', 'pork', 'horsemeat', 'lamb', 'chicken'],
    type: 'int',
    linkShow: {
      name: 'source',
      value: ['畜養', '畜産'],
      category: 'game',
    },
  },
  // 生体重量
  live_weight: {
    label: '生体重量',
    unit: 'kg',
    type: 'int',
  },
  // 規格
  standard: {
    label: '規格',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: ['A', 'B', 'C'].flatMap((v) =>
          [...Array(5)].map((_, i) => `${v}${5 - i}`)
        ),
      },
    ],
  },
  // 歩留等級
  yield_grade: {
    label: '歩留等級',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: ['A', 'B', 'C'],
      },
    ],
  },
  // 肉質等級
  meat_grade: {
    label: '肉質等級',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: ['5', '4', '3', '2', '1'],
      },
    ],
  },
  // 牛脂肪交雑基準(B.M.S.)
  BMS_score: {
    label: '牛脂肪交雑基準(B.M.S.)',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: [...Array(12)].map((_, i) => `No.${i + 1}`),
      },
    ],
  },
  // 牛肉色基準（B.C.S.）
  BCS_score: {
    label: '牛肉色基準（B.C.S.）',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: [...Array(7)].map((_, i) => `No.${i + 1}`),
      },
    ],
  },
  // 肉の光沢
  meat_luster: {
    label: '肉の光沢',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: [
          'かなり良いもの',
          'やや良いもの',
          '標準のもの',
          '標準に準ずるもの',
          '等級5～2以外のもの',
        ],
      },
    ],
  },
  // 肉の締まり
  meat_firmness: {
    label: '肉の締まり',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: [
          'かなり良いもの',
          'やや良いもの',
          '標準のもの',
          '標準に準ずるもの',
          '等級5～2以外のもの',
        ],
      },
    ],
  },
  // 肉のきめ
  meat_grain: {
    label: '肉のきめ',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: [
          'かなり細かいもの',
          'やや細かいもの',
          '標準のもの',
          '標準に準ずるもの',
          '粗いもの',
        ],
      },
    ],
  },
  // 牛脂肪色基準(B.F.S.)
  BFS_score: {
    label: '牛脂肪色基準(B.F.S.)',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: [...Array(7)].map((_, i) => `No.${i + 1}`),
      },
    ],
  },
  // 脂肪の光沢
  fat_luster: {
    label: '脂肪の光沢',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: [
          'かなり良いもの',
          'やや良いもの',
          '標準のもの',
          '標準に準ずるもの',
          '等級5～2以外のもの',
        ],
      },
    ],
  },
  // 瑕疵
  defects: {
    label: '瑕疵',
    show: ['beef'],
    isSideNav: true,
    select: [
      {
        values: [
          '多発性筋出血（シミ）',
          '水腫（ズル）',
          '筋炎（シコリ）',
          '外傷（アタリ）',
          '割除（カツジョ）',
          'その他',
        ],
      },
    ],
  },
  // 解体整形方法
  dissection_and_shaping_method: {
    label: '解体整形方法',
    show: ['pork'],
    isSideNav: true,
    select: [{ values: ['極上', '上', '中', '並', '等外'] }],
  },
  // 重量及び背脂肪の厚さの範囲
  weight_and_backfat_thickness_range: {
    label: '重量及び背脂肪の厚さの範囲',
    show: ['pork'],
    isSideNav: true,
    select: [{ values: ['極上', '上', '中', '並', '等外'] }],
  },
  // 外観等級（肉づき）
  appearance_grade_meatiness: {
    label: '外観等級（肉づき）',
    show: ['pork'],
    isSideNav: true,
    select: [{ values: ['極上', '上', '中', '並', '等外'] }],
  },
  // 外観等級（脂肪付着）
  appearance_grade_fat_attachment: {
    label: '外観等級（脂肪付着）',
    show: ['pork'],
    isSideNav: true,
    select: [{ values: ['極上', '上', '中', '並', '等外'] }],
  },
  // 外観等級（仕上げ）
  appearance_grade_finishing: {
    label: '外観等級（仕上げ）',
    show: ['pork'],
    isSideNav: true,
    select: [{ values: ['極上', '上', '中', '並', '等外'] }],
  },
  // 肉質等級（肉の締まり及びきめ）
  meat_grade_firmness_and_grain: {
    label: '肉質等級（肉の締まり及びきめ）',
    show: ['pork'],
    isSideNav: true,
    select: [{ values: ['極上', '上', '中', '並', '等外'] }],
  },
  // 肉質等級（肉の色沢）
  meat_grade_color: {
    label: '肉質等級（肉の色沢）',
    show: ['pork'],
    isSideNav: true,
    select: [{ values: ['極上', '上', '中', '並', '等外'] }],
  },
  // 肉質等級（脂肪の色沢と質）
  meat_grade_fat_color_and_quality: {
    label: '肉質等級（脂肪の色沢と質）',
    show: ['pork'],
    isSideNav: true,
    select: [{ values: ['極上', '上', '中', '並', '等外'] }],
  },
  // 肉質等級（脂肪の沈着）
  meat_grade_fat_deposition: {
    label: '肉質等級（脂肪の沈着）',
    show: ['pork'],
    isSideNav: true,
    select: [{ values: ['極上', '上', '中', '並', '等外'] }],
  },
  // 羊肉の種類
  lamb_type: {
    label: '羊肉の種類',
    show: ['lamb'],
    isSideNav: true,
    select: [{ values: ['ラム', 'マトン', 'ホゲット'] }],
  },
  // 外観（均称・肉付）
  symmetry_and_meatiness: {
    label: '外観（均称・肉付）',
    show: ['lamb'],
    isSideNav: true,
    select: [{ values: ['上', '中', '並', '等外'] }],
  },
  // 外観（脂肪付着）
  fat_attachment: {
    label: '外観（脂肪付着）',
    show: ['lamb'],
    isSideNav: true,
    select: [{ values: ['上', '中', '並', '等外'] }],
  },
  // 外観（仕上げ）
  finishing: {
    label: '外観（仕上げ）',
    show: ['lamb'],
    isSideNav: true,
    select: [{ values: ['上', '中', '並', '等外'] }],
  },
  // 肉質（肉のきめとしまり）
  meat_texture_and_firmness: {
    label: '肉質（肉のきめとしまり）',
    show: ['lamb'],
    isSideNav: true,
    select: [{ values: ['上', '中', '並', '等外'] }],
  },
  // 肉質（脂肪の色沢と質）
  fat_color_and_quality: {
    label: '肉質（脂肪の色沢と質）',
    show: ['lamb'],
    isSideNav: true,
    select: [{ values: ['上', '中', '並', '等外'] }],
  },
  // ブランド・銘柄
  brand_or_nameplate: {
    label: 'ブランド・銘柄',
    show: ['beef', 'pork', 'chicken', 'lamb'],
  },
  // 賞
  award: {
    label: '賞',
    show: ['beef', 'pork', 'horsemeat', 'chicken'],
  },
  // 個体識別情報又は荷口番号
  individual_identification_or_lot_number: {
    label: '個体識別情報又は荷口番号',
    show: ['beef'],
    linkRequired: {
      name: 'production_information_JAS_standard',
      value: true,
    },
  },
  // 個体識別情報、豚群識別番号または荷口番号
  individual_identification_information_or_pig_group_identification_number_or_lot_number: {
    label: '個体識別情報、豚群識別番号または荷口番号',
    show: ['pork'],
    linkRequired: {
      name: 'production_information_JAS_standard',
      value: true,
    },
  },
  // 配合飼料製造者
  compound_feed_manufacturer: {
    label: '配合飼料製造者',
    show: ['beef', 'pork', 'horsemeat', 'lamb', 'chicken'],
    linkShow: {
      name: 'source',
      value: ['畜養', '畜産'],
      category: 'game',
    },
  },
  // 単味飼料製造者
  single_ingredient_feed_manufacturer: {
    label: '単味飼料製造者',
    show: ['beef', 'pork', 'horsemeat', 'lamb', 'chicken'],
    linkShow: {
      name: 'source',
      value: ['畜養', '畜産'],
      category: 'game',
    },
  },
  // 粗飼料製造者
  roughage_manufacturer: {
    label: '粗飼料製造者',
    show: ['beef', 'pork', 'horsemeat', 'lamb', 'chicken'],
    linkShow: {
      name: 'source',
      value: ['畜養', '畜産'],
      category: 'game',
    },
  },
  // 注意事項
  notes: {
    label: '注意事項',
    linkRequired: {
      name: 'purpose',
      value: '生食用',
    },
  },
};

export const MEAT_ITEMS_DEFAULT_VALUES = (() => {
  const values = {};

  Object.keys(MEAT_ITEMS).forEach((k) => {
    const d = MEAT_ITEMS[k];
    if (d.defaultValue) {
      values[k] = d.defaultValue;
    }
  });

  return values;
})();

export const RAW_MESSAGE =
  '※一般的に食肉の生食は食中毒のリスクがあります。子どもや高齢者、食中毒に対する抵抗力が弱い人たちは生食を控えるべきです。';
export const RAW_MESSAGE_PORK =
  '※豚のお肉や内臓を生食用として販売・提供することは禁止されています。生で食べるとＥ型肝炎ウイルスや食中毒菌による重い食中毒が発生する危険があるからです。';
export const RAW_MESSAGE_GAME =
  '※一般的に生食は食中毒のリスクがあります。特に野生生物はリスクが高いので、生食は控えるべきです。ジビエを調理するときは、中心部まで火が通るよう、十分に加熱しましょう。調理に使用する器具は生肉用、加熱済み用と使い分けを行って、取り扱いには十分に注意しましょう。ジビエによる食中毒を防ぐには、中心部までしっかり加熱することが大切です。肉の中心部の色がしっかり変わっていることを確認しましょう。また、中心部の温度が75℃で1分以上、70℃なら3分以上が加熱殺菌の目安です。';

export const MEAT_CATEGORY = [
  {
    key: '0',
    value: '牛',
    name: 'category0',
    text: '牛',
  },
  {
    key: '1',
    value: '豚',
    name: 'category1',
    text: '豚',
  },
  {
    key: '2',
    value: '鶏',
    name: 'category2',
    text: '鶏',
  },
  {
    key: '3',
    value: '馬',
    name: 'category3',
    text: '馬',
  },
  {
    key: '4',
    value: '羊',
    name: 'category4',
    text: '羊',
  },
  {
    key: '5',
    value: 'ジビエ',
    name: 'category5',
    text: 'ジビエ',
  },
];

type CreateArrayDataOutput = {
  key: string;
  value: string;
  name: string;
  text: string;
}[];

/**
 * 選択肢のある値を返す
 * @param key
 * @returns
 */
export const CreateArrayData = (key: string): CreateArrayDataOutput => {
  const data = MEAT_ITEMS[key].select;
  if (!data) return [];

  const res = data.flatMap((item) => {
    const val = item.values.map((v) => (typeof v === 'string' ? v : v.value));

    return val.map((v) => ({
      key: ``,
      value: v,
      name: `${key}`,
      text: v,
    }));
  });

  return removeDuplicates(res).map((item, i) => ({
    ...item,
    key: `${i}`,
    name: `${key}${i}`,
  }));
};

const removeDuplicates = (arr: CreateArrayDataOutput) => {
  const uniqueMap = {};
  arr.forEach((obj) => {
    uniqueMap[obj.value] = obj;
  });

  const uniqueArray: CreateArrayDataOutput = Object.values(uniqueMap);
  return uniqueArray;
};

/**
 * 日付のデータの初期値を入力
 * @param values 現在の値
 * @returns
 */
export const ParseDateValue = (values: any) => {
  try {
    const res = {};

    for (const key in MEAT_ITEMS) {
      const d = MEAT_ITEMS[key];

      if (d.type === 'bool') {
        const value = values[key];
        res[key] = Boolean(value);
        continue;
      }

      if (d.type !== 'date') continue;

      const value = values[key];
      if (!value) continue;

      res[`${key}_year`] = moment(value).format('YYYY');
      res[`${key}_month`] = moment(value).format('M');
      res[`${key}_day`] = moment(value).format('D');
    }

    return res;
  } catch {
    return {};
  }
};
