import React, { useMemo } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';

import { paths } from '../../../../config';
import enhance from './enhancer';

function BidModal(props) {
  const {
    modalProps: {
      handleModal,
      handleSubmit,
      placeBids,
      shippingCost,
      coolDeliveryCost,
      shippingRelayCost,
      shippingExpense,
      isBidPopup,
    },
    form: {
      bidButton: {
        values: { amount },
      },
    },
    redListCheck,
    rareSpeciesCheck,
    toggleRedList,
    toggleRareSpecies,
    toggleIslandCheck,
    islandCheck,
  } = props;

  const submitDisabled = useMemo(() => {
    if (isBidPopup) return false;

    if (!redListCheck || !rareSpeciesCheck || !islandCheck) return true;
  }, [redListCheck, rareSpeciesCheck, islandCheck, isBidPopup]);

  return (
    <div id="sale_bid_modal">
      <div className="w__bid-message">
        <p className="bid_message">
          {`¥ ${Number(amount).toLocaleString()}`}
          まで自動入札してよろしいですか？
        </p>
      </div>

      {!isBidPopup && (
        <>
          <div className="compliance_wrap">
            <div className="compliance_red_list_wrap">
              <Checkbox
                onClick={toggleRedList}
                className="compliance_checkbox"
                id="bit_modal_red_list_checkbox"
              />
              <div>
                <label
                  htmlFor="bit_modal_red_list_checkbox"
                  className="compliance_label"
                >
                  入札する商品は絶滅危惧種に該当しないですか？
                </label>
                <br />
                <label
                  htmlFor="bit_modal_red_list_checkbox"
                  className="compliance_label"
                >
                  (絶滅危惧種リストは
                  <a
                    href="http://www.env.go.jp/press/files/jp/114457.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    こちら
                  </a>
                  )
                </label>
              </div>
            </div>
            <div className="compliance_rare_species_wrap">
              <Checkbox
                onClick={toggleRareSpecies}
                className="compliance_checkbox"
                id="bit_modal_rare_species_checkbox"
              />
              <div>
                <label
                  htmlFor="bit_modal_rare_species_checkbox"
                  className="compliance_label"
                >
                  入札する商品は国内希少種に該当しないですか？
                </label>
                <br />
                <label
                  htmlFor="bit_modal_rare_species_checkbox"
                  className="compliance_label"
                >
                  (国内希少種リストは
                  <a
                    href="https://www.env.go.jp/nature/kisho/domestic/list.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    こちら
                  </a>
                  )
                </label>
              </div>
            </div>

            <div className="remote-island-list-check">
              <Checkbox
                onClick={toggleIslandCheck}
                className="compliance_checkbox"
                id="remote_island_checkbox"
              />
              <div>
                <label
                  htmlFor="remote_island_checkbox"
                  className="compliance_label"
                >
                  佐川急便を利用した元払い配送で配送先が離島の場合、決済時に別途離島中継料金が加算されます。
                </label>
                <br />
                <label
                  htmlFor="remote_island_checkbox"
                  className="compliance_label"
                >
                  (
                  <a
                    href={`${paths.common.fee}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    対象地域と料金はこちら
                  </a>
                  )
                </label>
              </div>
            </div>
          </div>
        </>
      )}

      {shippingExpense === 3 ? (
        <table className="sale_prices">
          <thead>
            <tr>
              <th>送料</th>
              <th>離島中継料金</th>
              <th>クール料金</th>
              <th className="emphasis">決済予定価格</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>¥{shippingCost.toLocaleString()}</td>
              <td>¥{shippingRelayCost.toLocaleString()}</td>
              <td>¥{coolDeliveryCost.toLocaleString()}</td>
              <td className="sale_total_prices">
                ¥
                {(
                  Number(amount) +
                  shippingCost +
                  shippingRelayCost +
                  coolDeliveryCost
                ).toLocaleString()}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}

      <div className="sale_bid_modal_button">
        <Button negative onClick={handleModal} className="negative_button">
          キャンセル
        </Button>
        <Button
          positive
          onClick={handleSubmit(placeBids)}
          content="入札する"
          className="positive_button"
          disabled={submitDisabled}
        />
      </div>
    </div>
  );
}

export default enhance((props) => <BidModal {...props} />);
